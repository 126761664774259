.grid {
  padding: 16px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid > * {
  width: 450px;
  max-width: 100%;
}

.grid > * {
  background-color: rgba(1,1,1,0.3) !important;
}